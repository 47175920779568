import { WidgetProps } from '@wix/yoshi-flow-editor';
import React, { FC } from 'react';

import { StatusButton } from './StatusButton';

export type ControllerProps = {};

const Widget: FC<WidgetProps<ControllerProps>> = () => {
  return <StatusButton />;
};

export default Widget;
