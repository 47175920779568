import { useSettings } from '@wix/tpa-settings/react';
import React from 'react';
import { Button } from 'wix-ui-tpa/cssVars';

import settingsParams, { DisplayChoice } from '../../settingsParams';
import { classes } from './StatusButton.st.css';

export const StatusButton: React.FC = () => {
  const settings = useSettings();

  const displayType = settings.get(settingsParams.displayChoice);
  const text =
    displayType === DisplayChoice.PointsBalance
      ? 'points (100)'
      : displayType === DisplayChoice.Rewards
      ? 'rewards (10)'
      : 'program name';
  return <Button className={classes.root}>{text}</Button>;
};
