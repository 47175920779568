import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export enum DisplayChoice {
  PointsBalance = 'pointsBalance',
  Rewards = 'availableRewards',
  ProgramName = 'programName',
}

export type ISettingsParams = {
  displayChoice: SettingsParamType.String;
};

export default createSettingsParams<ISettingsParams>({
  displayChoice: {
    type: SettingsParamType.String,
    getDefaultValue: () => DisplayChoice.PointsBalance,
  },
});
